import { animation } from "utils/styles";
import Link from "next/link";
import Socials from "components/modules/Socials";

function Hero() {
  return (
    <main className="pt-0 lg:pt-14 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <div className="flex justify-center lg:justify-start">
            <div className="flex mb-6 bg-gradient-to-r bg-black text-white w-fit px-3 py-2 rounded-full space-x-5 ml-0.5">
              <Socials />
            </div>
          </div>
          <div className={`flex flex-col items-center lg:items-start`}>
            <HighlightText color="hover:text-red-400" text="Buy." link="/buy" />
            <HighlightText
              color="hover:text-yellow-400"
              text="Sell."
              link="/sell"
            />
            <HighlightText
              color="hover:text-pink-400"
              text="Create."
              link="/create"
            />
            <div className="font-sans text-3xl font-semibold text-center">
              in the meta economy.
            </div>
          </div>
        </div>
        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div
            className="relative mx-auto w-full rounded-2xl"
            style={{
              filter:
                "drop-shadow(rgba(122, 61, 143, 0.15) 0.5px 1px 1px) drop-shadow(rgba(122, 61, 143, 0.15) 1px 2px 2px) drop-shadow(rgba(122, 61, 143, 0.15) 2px 4px 4px) drop-shadow(rgba(122, 61, 143, 0.15) 4px 8px 8px) drop-shadow(rgba(122, 61, 143, 0.15) 8px 16px 16px)",
            }}
          >
            <div
              className="relative block bg-white rounded-2xl mx-auto overflow-hidden focus:outline-none"
              style={{ maxWidth: "40vw" }}
            >
              <video autoPlay muted loop>
                <source src="./img/home/featured.mp4" type="video/mp4"></source>
              </video>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function HighlightText({
  color,
  text,
  link,
}: {
  color: string;
  text: string;
  link: string;
}) {
  return (
    <Link passHref href={link}>
      <a className="w-fit">
        <div
          className={`${animation(
            150
          )} ${color} font-sans font-bold mb-2 hover:underline cursor-pointer rounded-md text-6xl sm:text-7xl xl:text-8xl`}
        >
          {text}
        </div>
      </a>
    </Link>
  );
}

export default Hero;
