import React from "react";

const features = [
  {
    name: "Low Fees",
    description: "Enjoy up to 100x lower fees.",
    icon: "",
    emoji: <p>&#x26FD;</p>,
  },
  {
    name: "Fully Featured",
    description: "Beautiful and full trading experience.",
    icon: "",
    emoji: <p>&#x1F4AA;</p>,
  },
  {
    name: (
      <a
        href="https://github.com/aspectco/cairo-contracts"
        target="_blank"
        rel="noreferrer"
        className="underline"
      >
        Deploy NFT Collection
      </a>
    ),
    description: "Deploy NFTs and let us handle the rest.",
    icon: "",
    emoji: <p>&#x1F5BC;</p>,
  },
  {
    name: "Secure & Composable",
    description: "Safe and scalable crypto proof system.",

    icon: "",
    emoji: <p>&#x1F6E1;</p>,
  },
];

function Feature() {
  return (
    <div className="py-24 lg:py-40 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-md mb-6 text-gray-600 font-bold tracking-wide uppercase">
            POWERED BY STARKWARE
          </h2>
          <a
            href="https://starknet.io/what-is-starknet/"
            target="_blank"
            className="font-sans hover:underline leading-8 font-extrabold tracking-tight text-gray-900 text-4xl sm:text-5xl"
            rel="noreferrer"
          >
            Hello, StarkNet
          </a>
          <p className="mt-6 max-w-xl text-xl text-gray-500 font-semibold lg:mx-auto leading-relaxed">
            Aspect is an NFT marketplace built on the most scalable & secure
            layer two blockchain on Ethereum.
          </p>
        </div>

        <div className="mt-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-16 md:gap-y-12">
            {features.map((feature) => (
              <div key={feature.description} className="relative">
                <dt>
                  <div
                    className={`absolute flex items-center justify-center h-12 w-12 rounded-md text-white`}
                  >
                    {feature.emoji}
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500 leading-relaxed">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default Feature;
