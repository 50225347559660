import type { ReactElement } from "react";
import { useEffect } from "react";
import { useRouter } from "next/router";

import Layout, { PAGE_TYPE } from "components/common/Layout";
import Hero from "components/common/Home/Hero";
import Feature from "components/common/Home/Feature";
import Testimonial from "components/common/Home/Testimonial";
import Team from "components/common/Home/Team";

import * as paths from "utils/paths";

import useStarknetStatusStore, {
  STARKNET_STATUS,
} from "stores/useStarknetStatusStore";

function Home() {
  const { status, enterAnyway } = useStarknetStatusStore((state) => state);
  const router = useRouter();

  useEffect(() => {
    if (status !== STARKNET_STATUS.ONLINE && !enterAnyway) {
      router.push(paths.status);
    }
  }, [router, status, enterAnyway]);

  return (
    <div>
      <Hero />
      <Feature />
      <Testimonial />
      <Team />
      {/* <Investors /> */}
    </div>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout pageType={PAGE_TYPE.HOME}>{page}</Layout>;
};

export default Home;
