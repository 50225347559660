import React from "react";
import Twitter from "components/modules/Icons/Twitter";
import GitHub from "components/modules/Icons/GitHub";

const people = [
  {
    name: "Sean",
    role: "Founder",
    imageUrl: "/img/home/cool-cat-ceo.png",
    textColor: "text-pink-400",
    twitterUrl: "https://twitter.com/0xs34n",
    githubUrl: "https://github.com/0xs34n",
  },
  {
    name: "Jon",
    role: "Founder",
    imageUrl: "/img/home/cool-cat-cto.png",
    textColor: "text-red-400",
    twitterUrl: "https://twitter.com/0xmonkeyy",
    githubUrl: "https://github.com/0xmonkeyy",
  },
  {
    name: "You",
    role: "Perfect role",
    textColor: "text-yellow-400",
    imageUrl: "",

    twitterUrl: "https://twitter.com/aspectdotco",
    githubUrl: "https://github.com/aspectco",
  },
];

function Team() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-12 lg:pt-40 lg:pb-20">
        <div className="space-y-8">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl font-sans">
              Squad
            </h2>
            <p className="text-xl text-gray-500">
              Crypto developers since &apos;17. &#x1F9E0; from Startups, Open
              Source, and Big Tech.
            </p>
          </div>
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className="space-y-4">
                  <div className="aspect-w-3 aspect-h-2">
                    {person.imageUrl ? (
                      <img
                        className="object-cover shadow-lg rounded-lg"
                        src={person.imageUrl}
                        alt=""
                      />
                    ) : (
                      <a
                        href="mailto:jobs@aspect.co?subject=Join Aspect!"
                        className="flex justify-center items-center shadow-lg rounded-lg text-white text-3xl font-bold"
                        style={{ backgroundColor: "#9CB5FE" }}
                      >
                        Join us!
                      </a>
                    )}
                  </div>

                  <div className="space-y-2">
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className={person.textColor}>{person.role}</p>
                    </div>
                    <ul role="list" className="flex items-center space-x-4">
                      <li>
                        <a
                          href={person.twitterUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Twitter</span>
                          <Twitter className="h-6 w-6" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={person.githubUrl}
                          className="text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">GitHub</span>
                          <GitHub className="h-6 w-6" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Team;
